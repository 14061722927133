import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <SEO
        title="Page Introuvable"
        description="Cette page n'existe plus"
      />
      <div className="error-404">
        <h1 className="mf-title-underscore"><span>Not found</span></h1>
        <div className="error-404__text">
          <p>Désolé, la page que vous recherchez n'existe pas ... <br />ou plus ... <br/>ou pas encore.</p>
          <p>En tous cas, il n'y a rien à voir ici !</p>
          <Link to="/" className="btn btn-outline-primary">Retour à l'accueil</Link>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
